<template>
  <div v-if="previewData" class="previewWapper">
    <a-spin v-if="onType === 'ppt'" :spinning="pptSpin">
      <div ref="pptView" class="pptView" style="width: 100%; height: 70vh"></div>
      <div class="deal">
        <p class="pptIcon" @click="preHandle"><a-icon type="step-backward" /></p>
        <p class="pptIcon" @click="playHandle"><a-icon type="forward" /></p>
        <p class="pptIcon" @click="nextHandle"><a-icon type="step-forward" /></p>
      </div>
    </a-spin>
    <div class="previewCls images" v-if="onType === 'image'">
      <img v-if="previewData" :src="previewData.url" alt="" :preview="previewData.url" />
    </div>
    <div class="previewCls" v-if="onType === 'video'">
      <video :src="videoUrl" controls="controls"></video>
    </div>
    <a-spin v-if="onType === 'pdf'" :spinning="pdfSpin">
      <div class="pdfCls">
        <pdfMain ref="pdf" :src="pdfUrl" v-for="i in numPages" :key="i" :page="i"> </pdfMain>
        <!-- <img v-for="(i, index) in previewData.convertFile" :key="index" :src="i.conversionFileUrl" alt="" /> -->
      </div>
    </a-spin>
  </div>
</template>
<script>
import { ConversionResponse, previewPPT } from 'white-web-sdk';
import pdfMain from 'vue-pdf';
import storage from 'store';

const config = ConversionResponse;
export default {
  name: 'pdf',
  components: {
    pdfMain,
  },
  data() {
    return {
      previewData: {},
      pptVisible: true,
      pptObj: null,
      typeStr: '',
      pdfUrl: '',
      videoUrl: '',
      numPages: null,
      isppt: false,
      count: 0,
      pptDom: null,
      scale: 100,

      pdfSpin: false,
      pptSpin: false,
    };
  },
  computed: {
    onType() {
      return this.initType();
    },
  },
  watch: {
    count() {
      this.loadingHandle();
    },
  },
  created() {
    const data = storage.get('materialPreviewData');
    this.previewData = data;
  },
  mounted() {
    this.$previewRefresh();
    if (this.initType() === 'ppt') {
      this.onFile();
    } else if (this.initType() === 'pdf') {
      this.getNumPages();
    }
  },
  updated() {
    this.$nextTick(() => {
      this.count = Math.random();
    });
  },
  methods: {
    initType() {
      const name = this.previewData?.name;
      const str = name?.substring(name.lastIndexOf('.') + 1);
      let type = '';
      if (['png', 'jpg', 'jpeg'].includes(str)) {
        type = 'image';
      } else if (['mp4'].includes(str)) {
        type = 'video';
        this.videoUrl = this.previewData?.url;
      } else if (['pdf'].includes(str)) {
        type = 'pdf';
        this.pdfUrl = this.previewData?.url;
      } else if (['ppt', 'pptx'].includes(str)) {
        type = 'ppt';
      }
      return type;
    },
    // 获取pdf页码
    getNumPages() {
      this.pdfSpin = true;
      const loadingTask = pdfMain.createLoadingTask(this.pdfUrl);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error('pdf 加载失败', err);
        })
        .finally(() => {
          this.pdfSpin = false;
        });
    },
    onFile() {
      const convertedFileList = this.previewData?.convertFile;

      const conversionResult = {
        uuid: 'c9831e50790a11eca05eedf33b3b6312',
        type: 'dynamic',
        status: 'Finished',
        failedReason: '',
        progress: {
          totalPageSize: convertedFileList.length,
          convertedPageSize: convertedFileList.length,
          convertedPercentage: 100,
          convertedFileList,
          status: 'Finished',
        },
      };
      this.$nextTick(() => {
        this.pptObj = previewPPT(conversionResult, this.$refs.pptView, config, true);
        this.count = Math.random();
        this.pptDom = document.getElementById('ppt-animation-hub');
        this.pptObj.on('update', () => {
          this.count = Math.random();
        });
      });
    },
    nextHandle() {
      this.count = Math.random();
      this.pptObj.emit('nextPage');
    },
    playHandle() {
      this.pptObj.emit('nextStep');
    },
    preHandle() {
      this.count = Math.random();
      this.pptObj.emit('prePage');
    },
    loadingHandle() {
      this.pptSpin = !document.getElementById('ppt-animation-hub');
    },
    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>
<style lang="less">
.previewWapper {
  max-width: 800px;
  min-height: 100vh;

  margin: 0 auto;
}

.deal {
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: #333;
  gap: 20px;
  .pptIcon {
    cursor: pointer;

    display: flex;
    justify-content: center;
  }
}
.ppt-preview-beauty-scroll-bar {
  display: none;
}
.previewCls {
  width: 100%;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.images {
  img {
    vertical-align: middle;
    width: 100%;
    max-height: 100vh;
  }
}
.pdfCls {
  width: 100%;

  min-height: 100vh;
  img {
    display: block;
    margin: auto;
  }
}
.loading {
  width: calc(100vw - 60px);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  line-height: 100%;
  font-size: 60px;
  position: absolute;
  top: 0 !important;
  z-index: 9999;
}
</style>
